import React, {Component} from 'react';
import {v4} from 'uuid';
import './styles/platforms-nav.css';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class Item {
  constructor(name, icon, url = '#') {
    this.name = name;
    this.icon = icon;
    this.url = url;
  }
}

class PlatformsNav extends Component {
  constructor() {
    super();

    this.state = {
      active: 100
    };

    this.menuItems = [
      new Item('Overview', 'home', 0),
      new Item('Web', ['fab', 'optin-monster'], 1),
      new Item('Android', ['fab', 'android'], 2),
      new Item('iOS', ['fab', 'app-store-ios'], 3),
      new Item('Windows', ['fab', 'windows'], 4),
      new Item('Linux', ['fab', 'linux'], 5),
      new Item('Hardware', ['fab', 'whmcs'], 6),
      new Item('Hobies', ['fas', 'heartbeat'], 7),
      new Item('Design', ['fab', 'phoenix-framework'], 8),
      new Item('Certificates', ['fas', 'graduation-cap'], 9)
    ];
  };

  onClickHandler = (sendItemIndex) => {
    this.setState({
      active: sendItemIndex
    });
    this.props.onSwitch(sendItemIndex);
  };

  MenuItem = ({item}) => {
    const isActive = (item.url === this.state.active) ?
    {opacity: 1, borderBottom: '2px solid #de4a32'} : {};

    return (
      <li>
        <a
          href="#portfolio"
          style={isActive}
          onClick={() => this.onClickHandler(item.url)}>
          <div className="platforms-icon">
            <FontAwesomeIcon icon={item.icon} size="2x" />
          </div>
          <div className="platforms-name">{item.name}</div>
        </a>
      </li>
    );
  };

  render() {
    return (
      <nav className="navPlatforms">
        <div className="inner">
          <ul>
            {this.menuItems.map(e => <this.MenuItem item={e} key={v4()} /> )}
          </ul>
        </div>
      </nav>
    );
  };
};

export default PlatformsNav;
