import React, { Component } from 'react';
import './styles/default-section.css';
import {FirstSection, SecondSection, ThirdSection,
FourthSection, FifthSection} from './SectionsCode.js';
import kyoto from './resources/kyotoKon.jpg';
import skytree from './resources/skytree.jpg';
import osaka from './resources/osaka.jpg';

import PlatformNav from './PlatformNav.js';

class Sections extends Component {
  constructor(){
    super();
    this.state = {
      navSelected: 100
    };
  };

  onSelect = (select) => {
    this.setState({
      navSelected: select
    });
  }

  render() {
    return (
      <div style={styles.divCon}>
        <section style={styles.section1} className={this.props.css}>
          <FirstSection />
        </section>

        <section id="portfolio" style={styles.nav}>
          <PlatformNav onSwitch = {this.onSelect} />
        </section>

        <section style={styles.section4} className={this.props.css}>
          <FourthSection choice={this.state.navSelected} />
        </section>

        <section style={styles.section2} className={this.props.css}>
          <SecondSection />
        </section>

        <section id="skills" style={styles.section5} className={this.props.css}>
          <FifthSection />
        </section>

        <section id="about" style={styles.section3} className={this.props.css}>
          <ThirdSection />
        </section>

        <section style={{order: 7}}>
          <PlatformNav onSwitch = {this.onSelect} />
        </section>
    </div>
    );
  }
}

export default Sections;

const styles = {
  section1: {
    order: 1,
    background: '#e1e1e1 no-repeat center center',
    backgroundSize: 'cover',
    backgroundImage: `url(${kyoto})`,
    backgroundColor: '#15190a'
  },
  section2: {
    order: 4,
    background: '#e1e1e1 no-repeat center center',
    backgroundSize: 'cover',
    backgroundImage: `url(${osaka})`,
    backgroundColor: '#15190a'
  },
  section3: {
    order: 6,
    background: '#e1e1e1 no-repeat center center',
    backgroundSize: 'cover',
    backgroundImage: `url(${skytree})`,
    backgroundColor: '#15190a'
  },
  section4: {
    order: 3,
    height: 'auto'
  },
  section5: {
    order: 5,
    minHeight: 950,
    height: 'auto'
  },
  nav: {
    order: 2
  },
  divCon: {
    display: 'flex',
    flexDirection: 'column'
  }
};
