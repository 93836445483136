import React, { Component } from 'react';
import Modal from 'react-modal';

import './styles/header.css';
import Logo from './resources/logo.png';

Modal.setAppElement('#root');

class Header extends Component {
  constructor(){
    super();

    this.state = {
      loginIsOpen: false,
      signUpIsOpen: false
    };

    this.menu = [
      {text: 'About', url: '#about'},
      {text: 'Portfolio', url: '#portfolio'},
      {text: 'Skills', url: '#skills'}
    ];

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal = (which) => {
    (which === 'login')
    ? this.setState({loginIsOpen: true})
    : this.setState({signUpIsOpen: true});
  }

  closeModal() {this.setState({loginIsOpen: false, signUpIsOpen: false});}

  render() {
    return (
      <header>
        <div className="inner">
          <div className='logo'>
            <a href="">
              <img src={Logo} alt="logo" style={styles.logo} />
            </a>
          </div>
          <div className='top-menu'>
            <nav>
              <ul>
                {this.menu.map((link, index) =>
                  <li key={index}>
                    <a href={link.url}>{link.text}</a>
                  </li>
                )}
                <li><a onClick={() => this.openModal('login')}>Login</a></li>
                <li><a onClick={() => this.openModal('SignUp')}>Sign Up</a></li>
              </ul>
            </nav>
          </div>
        </div>

<Modal
  isOpen={this.state.loginIsOpen}
  onRequestClose={this.closeModal}
  style={customStyles}
  contentLabel="Login Modal"
  >
    <form className="modal-content" action="">

      <div className="imgcontainer">
        <img src={Logo} alt="Avatar" className="avatar" />
      </div>

      <div className="container">
        <label htmlFor="uname"><b>Username</b></label>
        <input type="text" placeholder="Enter Username" name="uname" required />

        <label htmlFor="psw"><b>Password</b></label>
        <input type="password" placeholder="Enter Password" name="psw" required />

        <button className="login" type="submit">Login</button>
        <label>
          <input type="checkbox" defaultChecked name="remember" /> Remember me
        </label>
      </div>

      <div className="container-footer" style={{backgroundColor:'#f1f1f1'}}>
        <button
          type="button"
          className="cancelbtn"
          onClick={this.closeModal}
          >Cancel
        </button>
        <span className="psw">Forgot <a href="">password?</a></span>
      </div>
    </form>
</Modal>

<Modal
  isOpen={this.state.signUpIsOpen}
  onRequestClose={this.closeModal}
  style={customStyles}
  contentLabel="Sign Up Modal"
  >

  <form className="modal-content sign-modal" action="">
    <div className="container">
      <h1>Sign Up</h1>
      <p>Please fill in this form to create an account.</p>
      <hr />
      <label htmlFor="email"><b>Email</b></label>
      <input type="email" placeholder="Enter Email" name="email" required />

      <label htmlFor="psw"><b>Password</b></label>
      <input type="password" placeholder="Enter Password" name="psw" required />

      <label htmlFor="psw-repeat"><b>Repeat Password</b></label>
      <input type="password" placeholder="Repeat Password" name="psw-repeat" required />

      <label>
        <input type="checkbox" defaultChecked name="remember" style={{marginBottom:15}} /> Remember me
      </label>

      <p>By creating an account you agree to our&nbsp;
        <a href="" style={{color:'dodgerblue'}}>Terms & Privacy</a>.
      </p>

      <div className="clearfix">
        <button type="button" className="sign-cancel-btn" onClick={this.closeModal}>Cancel</button>
        <button type="submit" className="signupbtn">Sign Up</button>
      </div>
    </div>
  </form>
</Modal>

      </header>
    );
  }
}

export default Header;

const styles = {
  logo: {
    width: 230,
    marginTop: 4
  }
};

const customStyles = {
  overlay: {
  backgroundColor       : 'rgba(0, 0, 0, 0.75)'
  },
  content : {
    backgroundColor       : 'rgba(255, 255, 255, 0)',
    border                : 0,
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};
