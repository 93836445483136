import React, { Component } from 'react';

class Button extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false
    };

    this.btnStyle = this.btnStyle.bind(this);
  }

  btnStyle = ({ isHovered }) => {
    let { textColor, onTextColor, bgColor, onBgColor } = this.props;

    if (!textColor) textColor = '#ffff';
    if (!onTextColor) onTextColor = '#ffff';
    if (!bgColor) bgColor = 'rgba(163, 2, 2, 0.6)';
    if (!onBgColor) onBgColor = 'rgba(163, 2, 2, 1)';

    return {
      display: 'block',
      minWidth: '100px',
      backgroundColor: (isHovered) ? onBgColor : bgColor,
      color: (isHovered) ? onTextColor : textColor,
      textAlign: 'center',
      padding: '5px 0px',
      borderRadius: 5,
      border: '0px solid #ffff',
      cursor: 'pointer',
      transition: 'all 0.5s'
    };
  };

  render() {
    const { url, text } = this.props;

    return (
      <button
        onMouseOver={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
        onClick={() => window.location.href=url}
        style={this.btnStyle(this.state)}>{text}
      </button>
    );
  };
};

export { Button };
