import React from 'react';
import phoneMockUp from './resources/iphoneSE.png';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Portfolio from './Portfolio.js';
import { Button } from './common';

export const FirstSection = () => {
  return (
    <div className="inner section1">
      <div><h1>Capture the moment, or let it slip.</h1></div>
      <div><h2>Be more productive by using my Apps and skills at no cost.</h2></div>

      <div>
        <Button
          url="https://konst.herokuapp.com/"
          text="Try NOW!"
        />
      </div>
      <div style={{height: 150, width: 100}}>
        <div id="frame" style={{backgroundImage: `url(${phoneMockUp})`}}>
          <iframe
            title="MockUp"
            id="wrap"
            src="https://konst.herokuapp.com/"
            scrolling="yes">
          </iframe>
        </div>
      </div>
    </div>
  );
};

export const SecondSection = () => {
  return (
    <div className="inner section2">
      <div>
        <div className="cont">
        <span className="shadow">
          <h1>Lets go for a walk</h1>
          <p style={{marginBottom: 10, lineHeight: 1}}>
            As far back as I remember I always wanted to interact with
            different cultures. I always had the curiousity about new
            things and what really makes me happy is making good
            friends from all over the world.
          </p>
        </span>
        </div>
      </div>

    </div>
  );
};

export const ThirdSection = () => {
  return (
    <div className="inner section3">
      <div>
        <div className="cont">
          <span className="shadow">
            <h1>It looks so empty without about me</h1>
            <p>
              I am a Capricorn and the design pattern is if you ever do something,
              do it with style. <br /> <br /> It is the same as HTML without the CSS.
              <br /><br /> HTML by itself wont teach you How-To-Meet-Ladies.<br />
              You need JavaScript! <br /><br />
            </p>
            <div>
              <Button
                url="https://www.linkedin.com/in/konstantin-dinev/"
                text="Read more"
                bgColor="rgb(0, 0, 204, 0.6)"
                onBgColor="rgb(0, 0, 204, 1)"
              />
              <span style={{ marginLeft: 10 }} />
              <Button
                url="#"
                text="Go back"
              />
            </div>
         </span>
        </div>
      </div>

    </div>
  );
};

export const FourthSection = ( {choice} ) => {

  switch (choice) {
    case 0: return <Portfolio.Overview />;
    case 1: return <Portfolio.Web />;
    case 2: return <Portfolio.Android />;
    case 3: return <Portfolio.iOS />;
    case 4: return <Portfolio.Windows />;
    case 5: return <Portfolio.Linux />;
    case 6: return <Portfolio.Hardware />;
    case 7: return <Portfolio.Hobies />;
    case 8: return <Portfolio.Design />;
    case 9: return <Portfolio.Certificates />;
    // eslint-disable-next-line
    default: return <Portfolio.DefaultPage />;
  };
};

export const FifthSection = () => {
  class ArticleInfo {
    constructor(icon, title, description) {
      this.icon = icon;
      this.title = title;
      this.description = description;
    }
  }

  const icons =
  [
    'code',
    ['fab','node-js'],
    'database',
    ['fab','js'],
    'edit',
    'calendar-alt',
    'mobile',
    'mobile-alt',
    'desktop',
    'cloud',
    'file-code',
    'folder-open'
  ];

  const titles = [
    'Front-end',
    'Back-end',
    'DataBases',
    'JavaScript',
    'Text Editors',
    'Frameworks',
    'Native Mobile',
    'Hybrid Mobile',
    'Desktop Development',
    'Cloud Computing',
    'IDE',
    'Operating Systems'
  ];

  const desc = [
    'HTML5, CSS3, JavaScript, Responsive Web Apps, Single-Page Applications, MBaaS',
    'Node.js, Express Framework, Firebase, C# ASP.NET MVC 5',
    'MySQL, Microsoft SQL Server, SQL, sqlite3, MS Access, NoSQL',
    'EcmaScript 2015, Object Oriented JavaScript, React Framework, Vannila JS',
    'Sublime Text 3, Atom, Nuclide, Nano',
    'React Framework, .NET Framework, .NET Core, Mono Framework, Entity Framework',
    'Cross-Platform Programming Tools Xamarin and React Native ',
    "Apache Cordova, PhoneGap",
    'C# .NET, OOP Principles, Data Structures, Algorithms',
    'Salesforce, Kii Cloud, Firebase, Heroku, Amazon Web Services, Azure DevOps',
    'Visual Studio 2017',
    'Mac OSX, Linux and Microsoft Windows'
  ];

  const articleArr = [];

  for (let i = 0; i < titles.length; i++) {
    articleArr[i] = new ArticleInfo(icons[i], titles[i], desc[i]);
  }

  const ArticleItem = ({arObj}) => {
    return (
      <article>
        <FontAwesomeIcon icon={arObj.icon} size="2x" color="#de4a32" />
        <h3>{arObj.title}</h3>
        <p>{arObj.description}</p>
      </article>
    );
  };

  return (
    <div className="inner section5">
        <div>
          <h2>Computer Engineering</h2>
          <p className="sub-header">
            During the university years, I have acquired proficient skills with the
            listed technologies. I graduated my bachelor and master studies with an
            outstanding academic achievements. I have also managed to get an international
            experience by working as a software engineer in Hungary, Romania and Japan.
          </p>

           <div className="article-wrapper">
             {articleArr.map((item, index) =>
               <ArticleItem arObj={item} key={index} />
             )}
           </div>

        </div>
    </div>
  );
};
