import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Lightbox from 'react-image-lightbox';
import Gallery from 'react-grid-gallery';
import { CSSTransitionGroup } from 'react-transition-group';
import './styles/portfolio.css';
import phoneMockUp from './resources/iphoneSE.png';
import phoneGalaxy from './resources/galaxy.png';
import lightPolution from './resources/light-polution.jpg';

import attestation from './resources/Certs/attestation.jpg';
import erasmus1 from './resources/Certs/erasmus1.jpg';
import erasmus20 from './resources/Certs/erasmus20.jpg';
import erasmus21 from './resources/Certs/erasmus21.jpg';
import nihongoCert from './resources/Certs/nihongoCert.jpg';
import vulcanusCert from './resources/Certs/vulcanusCert.jpg';

class TaskInfo {
  constructor(icon, description, icoSize = '6x') {
    this.icon = icon;
    this.description = description;
    this.icoSize = icoSize;
  };
};

const ListItem = ({taskObj}) => {
  return (
  <li className="tasksLI">
    <div className="icon">
      <FontAwesomeIcon icon={taskObj.icon} size={taskObj.icoSize} />
    </div>
    <div className="desc">{taskObj.description}</div>
  </li>
)};

const taskArr = [
  new TaskInfo(['fab', 'angellist'], 'Simplicity Over Complexity'),
  new TaskInfo(['fab', 'studiovinari'], 'Collaborative Intelligence'),
  new TaskInfo(['fab', 'angrycreative'], 'Think Different By Heart')
];
const overview = [
  new TaskInfo(['fab', 'react'], 'React Framework'),
  new TaskInfo(['fab', 'node'], 'Node JS'),
  new TaskInfo(['fab', 'aws'], 'Amazon Web Services')
];
const hardware = [
  new TaskInfo(['fas', 'cogs'], 'Replacing Components', '4x'),
  new TaskInfo(['fas', 'handshake'], 'Customer Satisfaction ', '4x'),
  new TaskInfo(['fab', 'cc-amazon-pay'], 'Trading on eBay', '4x')
];
const hobies = [
  new TaskInfo(['fab', 'grav'], 'Traveling', '4x'),
  new TaskInfo(['fab', 'fort-awesome-alt'], 'PlayStation', '4x'),
  new TaskInfo(['fab', 'themeisle'], 'Nature', '4x'),
  new TaskInfo(['fas', 'futbol'], 'Sport', '4x'),
  new TaskInfo(['fab', 'rebel'], 'StarWars', '4x'),
  new TaskInfo(['fas', 'camera-retro'], 'Photography', '4x'),
];

const DefaultPage = () => {
  return (
    <div className="inner section4">
        <div>
          <h2>Welcome to my personal Website</h2>
          <p className="sub-header">
            I hope by reading this website you can get to know me better.
            You can use the above menu to review my projects.
            A few of my virtues which I consider important could be seen below.
          </p>
          <ul className="tasksUL">
            {taskArr.map((item, index) =>
              <ListItem taskObj={item} key={index}/>
            )}
          </ul>
        </div>
    </div>
  );
};

const Overview = () => {
  return (
    <div className="inner section4">
        <div>
          <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
            <h2>Overview</h2>
            <p className="sub-header">
              I built this Website on top of the React Framework.
              Everything you can see on this web-page is written from scratch.
              The reason I did this is to show what I'm capable of
              by helping recruiters making their right choice easily.
              I'm also publishing projects that I have worked on as a portfolio.
              The programming tools which I used are shown below.
            </p>
            <ul className="tasksUL">
              {overview.map((item, index) =>
                <ListItem taskObj={item} key={index}/>
              )}
            </ul>
          </CSSTransitionGroup>
        </div>
    </div>
  );
};

class List {
  constructor(imgSrc={src:'', alt:''}, desc, url={href: null, txt: null}) {
    this.imgSrc = imgSrc;
    this.desc = desc;
    this.url = url;
  };
};

const Web = () => {
  const webProj = [
    new List(
      {src: '/media/gourmet.jpg', alt: 'mushrooms'},
      'A Bulgarian company to introduce Japanese cultures in Europe',
      {href: 'http://gourmetmushrooms.eu', txt:'Gourmet Mushrooms'}
    ),
    new List(
      {src: '/media/todo.jpg', alt: 'App'},
      'Sign Up to get synchronization across your devices.',
      {href: 'https://konst.herokuapp.com', txt: 'To Do App'}
    ),
  ];

  return (
    <div className="inner section4">
        <div>
          <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
            <h2>Web Projects</h2>
            <p className="sub-header">
              Developing web solutions since the design layout was organized in tables.
            </p>
            <ul className="tasksUL">
              {webProj.map((item, index) =>
                <li key={index} className="tasksLI">
                  <div className="gallery">
                    <a target="_blank" href={item.url.href}>
                      <img src={item.imgSrc.src} alt={item.imgSrc.alt} width="300" height="200" />
                    </a>
                      <div className="desc">
                        {item.desc}
                      </div>
                      <div className="gallery-foot">
                        <a target="_blank" href={item.url.href}>{item.url.txt}</a>
                      </div>
                  </div>
                </li>
              )}
            </ul>
          </CSSTransitionGroup>
        </div>
    </div>
  );
};

class Phone {
  constructor(styleImg = {}, src, projName){
    this.styleImg = styleImg;
    this.src = src;
    this.projName = projName;
  }
}

const Android = () => {
  const phones = [
    new Phone({backgroundImage: `url(${phoneGalaxy})`}, 'https://www.iConstantine.com', 'this.Website'),
    new Phone({backgroundImage: `url(${phoneGalaxy})`}, "https://konst.herokuapp.com", 'React ToDO List'),
    new Phone({backgroundImage: `url(${phoneGalaxy})`}, lightPolution, <a href='/media/Const-iOSpps.ppsx'>Xamarin Native</a>),
  ]
  return (
    <div className="inner section4 portfolio-section">
      <div className="portfolio">
        <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={500}
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}>
          <h2>Android Development</h2>
          <p className="sub-header">
            My solutions are cross-platform and runs Natively everywhere.
          </p>
          <ul>
            {phones.map((item, key) =>
              <li key={key}>
                <div><div className="sub-header">{item.projName}</div></div>
                <div>
                  <div className="div-wrapper">
                    <div className="android-phone" style={item.styleImg}>
                      <iframe
                        title="MockUp"
                        className="android-wrap"
                        src={item.src}
                        scrolling="yes">
                      </iframe>
                    </div>
                  </div>
                </div>
              </li>
            )}
        </ul>
      </CSSTransitionGroup>
      </div>
    </div>
  );
};

const iOS = () => {
  const iPhones = [
    new Phone({backgroundImage: `url(${phoneMockUp})`}, 'https://www.iConstantine.com', 'this.WebSite'),
    new Phone({backgroundImage: `url(${phoneMockUp})`}, 'https://konst.herokuapp.com', 'React ToDO List'),
    new Phone({backgroundImage: `url(${phoneMockUp})`}, 'https://konstantindinev.github.io/TicTacToe/iFrameTest/salesforceApp.html', 'SalesForce Contacts'),
  ]
  return (
    <div className="inner section4 portfolio-section">
      <div className="portfolio">
        <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={500}
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}>
          <h2>iOS Development</h2>
          <p className="sub-header">
            Sign-up for my Apps and get a free synchronization between your tasks.
            Get in touch with me regarding features On-Demand and personalized experience.
          </p>
          <ul>
            {iPhones.map((item, key) =>
              <li key={key}>
                <div><div className="sub-header">{item.projName}</div></div>
                <div>
                  <div className="div-wrapper">
                    <div className="phone" style={item.styleImg}>
                      <iframe
                        title="MockUp"
                        className="wrap"
                        src={item.src}
                        scrolling="yes">
                      </iframe>
                    </div>
                  </div>
                </div>
              </li>
            )}
        </ul>
      </CSSTransitionGroup>
      </div>
    </div>
  );
};

const Linux = () => {
  const linuxes = [
    new List(
      {src: '/media/elementary.png', alt: 'beauty'},
      'Elementary OS',
      {width:80, height: 80}
    ),
    new List(
      {src: '/media/kali-linux.jpg', alt: 'my girlfriend'},
      'Pentesting the securites',
      {width: 240, height: 80}
    ),
    new List(
      {src: '/media/debian.png', alt: 'powerful'},
      'Runs on my PlayStation',
      {width: 250, height: 80}
    )
  ];
  return (
    <div className="inner section4">
        <div>
          <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
            <h2>Linux</h2>
            <p className="sub-header">
              Once you get your hands on, there is no going back. <br />
              Beautiful, Secured and Reliable
            </p>

            <ul className="tasksUL">
              {linuxes.map((linux, index) =>
                <li key={index} className="tasksLI">
                  <div>
                      <img
                        src={linux.imgSrc.src}
                        alt={linux.imgSrc.alt}
                        width={linux.url.width} height={linux.url.height}
                      />
                      <div className="desc">
                        {linux.desc}
                      </div>
                  </div>
                </li>
              )}
            </ul>
          </CSSTransitionGroup>
        </div>
    </div>
  );
};

const Windows = () => {
  return (
    <div className="inner section4">
        <div>
          <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
            <h2>Microsoft Windows</h2>
            <p className="sub-header">The first time that I used a computer was on Windows 3.11</p>
            <img style={{padding: 25, width: '65%'}} src="/media/win3-11.gif" alt="memories" />
            <p style={{paddingBottom: 25}} className="sub-header">
              I wrote a guide on how to connect remotely to your Windows System&nbsp;
              <a href="https://github.com/KonstantinDinev/SSH-Windows">here</a>
            </p>
          </CSSTransitionGroup>
        </div>
    </div>
  );
};

const Hardware = () => {
  return (
    <div className="inner section4">
      <div style={{minHeight: 328}}>
        <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={500}
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}>
          <h2>Hardware</h2>
          <p className="sub-header">
            Assembling, Dissasembling and Servicing hardware electronics. <br />
            Laptop and Desktop Computers, Mobile Devices, Consoles <br />
          </p>
          <ul className="tasksUL">
            {hardware.map((item, index) =>
              <ListItem taskObj={item} key={index}/>
            )}
          </ul>
        </CSSTransitionGroup>
      </div>
    </div>
  );
};

const Hobies = () => {
  return (
    <div className="inner section4">
        <div style={{minHeight:328}}>
          <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
            <h2>Hobies</h2>
            <p className="sub-header"></p>
            <ul className="tasksUL">
              {hobies.map((hobi, index) =>
                <ListItem taskObj={hobi} key={index} />
              )}
            </ul>
          </CSSTransitionGroup>
        </div>
    </div>
  );
};

class Design extends React.Component {
  constructor() {
    super();

    this.state = {
      photoIndex: 0,
      isOpen: true
    };

    this.openLightbox = this.openLightbox.bind(this);
  }

  openLightbox(event, obj) {
    this.setState({
      photoIndex: obj.index,
      isOpen: true,
    });
  };

  render() {
    const {photoIndex, isOpen} = this.state;
    const images = [
      '/media/design/drawings.jpg',
      '/media/design/draw.jpg',
      '/media/design/draw1.jpg',
      '/media/design/HDR-GS.jpg',
      '/media/design/mush.png',
      '/media/design/ps.jpg',
      '/media/design/photoshop.jpg',
      '/media/design/banners.jpg',
      '/media/design/banners1.jpg',
      '/media/design/banners2.jpg'
    ];

    return (
      <div className="inner section4">
          <div style={{minHeight: 125}}>
            <h2>Design</h2>
            <p className="sub-header">
              Hand Drawing and Adobe Products
            </p>

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({photoIndex: (photoIndex + images.length - 1) % images.length,})}
                onMoveNextRequest={() =>
                  this.setState({photoIndex: (photoIndex + 1) % images.length,})}
              />
            )}
          </div>
      </div>
    );
  }
};

class Certificates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: true
    };

    this.openLightbox = this.openLightbox.bind(this);
  };

  openLightbox(event, obj) {
    this.setState({
      photoIndex: obj.index,
      isOpen: true,
    });
  };

  render() {
    const {photoIndex, isOpen} = this.state;

    const images = [
      vulcanusCert, nihongoCert, attestation,
      erasmus1, erasmus20, erasmus21
    ];

    const photos = [
      { src: vulcanusCert,
        thumbnail: vulcanusCert,
        thumbnailWidth: 372,
        thumbnailHeight: 260,
        isSelected: false,
        caption: ''
      },
      { src: nihongoCert,
        thumbnail: nihongoCert,
        thumbnailWidth: 200,
        thumbnailHeight: 260,
        isSelected: false,
        caption: ''
      },
      { src: attestation,
        thumbnail: attestation,
        thumbnailWidth: 200,
        thumbnailHeight: 260,
        isSelected: false,
        caption: ''
      },
      { src: erasmus1,
        thumbnail: erasmus1,
        thumbnailWidth: 150,
        thumbnailHeight: 260,
        isSelected: false,
        caption: ''
      },
      { src: erasmus20,
        thumbnail: erasmus20,
        thumbnailWidth: 150,
        thumbnailHeight: 260,
        isSelected: false,
        caption: ''
      },
      { src: erasmus21,
        thumbnail: erasmus21,
        thumbnailWidth: 150,
        thumbnailHeight: 260,
        isSelected: false,
        caption: ''
      }
    ];

    return (
      <div className="inner section4">
        <div>
          <h2>Honors and Rewards</h2>
          <p className="sub-header">Acknowledgement</p>

          <div style={
            {
               width: '80%',
               margin: '0 auto',
               marginTop: 30,
               marginBottom: 250
            }
          }>

            <Gallery
              images={photos}
              onClick={this.openLightbox}
              backdropClosesModal={true}
            />
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({photoIndex: (photoIndex + images.length - 1) % images.length,})}
              onMoveNextRequest={() =>
                this.setState({photoIndex: (photoIndex + 1) % images.length,})}
            />
          )}
        </div>
      </div>
    );
  };
};

const Portfolio = { DefaultPage, Overview, Web, Android, iOS, Linux,
                    Windows, Hardware, Hobies, Design, Certificates};

export default Portfolio;
