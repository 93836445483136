import React, { Component } from 'react';
import './App.css';

import Header from './components/Header.js';
import Sections from './components/Sections.js'
import {Footer, SocialMedia} from './components/Footer.js'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Sections css="Default-Section" />
        <SocialMedia />
        <Footer />
      </div>
    );
  }
}

export default App;
