import React from 'react';
import './styles/footer.css';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import brands from '@fortawesome/fontawesome-free-brands';
import solid from '@fortawesome/fontawesome-free-solid';
import fontawesome from '@fortawesome/fontawesome';

fontawesome.library.add(brands, solid);

// Footer menues
export const Footer = () => {
  class Menu {
    constructor(title = '', items = [{label: 'default', url: ''}]) {
      this.title = title;
      this.items = [];
      for (let item of items) this.items.push(item);
    }
  }

  const menuItemsLearn = [
    {label: 'On-Demand', url: '#'},
    {label: 'Portfolio', url: '#portfolio'}
  ];

  const menuLearn = new Menu('LEARN MORE', menuItemsLearn);

  const menuItemsHelp = [
    {label: 'Support', url: '#'},
    {label: 'Terms of Service', url: '#'}
  ];

  const menuHelp = new Menu('HELP CENTER', menuItemsHelp);

  const menuItemsAbout = [
    {label: 'Send Email', url: 'mailto:konstdinev@gmail.com'},
    {label: 'Call me on Skype', url: 'skype:zergling_?call'}
  ];

  const menuAbout = new Menu('CONTACTS', menuItemsAbout);

  const MenuComponent = ({selectMenu}) => {
    return (
      <div className="container">
        <div className="menu">
          <h2>{selectMenu.title}</h2>
          <ul>
            {selectMenu.items.map((item, index) =>
              <li key={index}>
                <a href={item.url}>{item.label}</a>
              </li>)}
          </ul>
        </div>
      </div>
    );
  };


  const menues = [menuLearn, menuHelp, menuAbout];
  //Footer render
  return (
    <section className="footer">
        {menues.map((menu, index) =>
          <MenuComponent selectMenu={menu} key={index} />
        )}
    </section>
  );
}

// Social Media Menu
export const SocialMedia = () => {
  class MediaIcon {
    constructor(icon = ['fab', 'apple'], url, size = '2x', color = '#919191') {
      this.icon = icon;
      this.color = color;
      this.size = size;
      this.url = url;
    }
  }

  const icons = [
    new MediaIcon(['fab', 'linkedin'], 'https://www.linkedin.com/in/konstantin-dinev/'),
    new MediaIcon(['fab', 'twitter'], 'https://twitter.com/ConstCyrillus'),
    new MediaIcon(['fab', 'github'], 'https://github.com/KonstantinDinev'),
    new MediaIcon(['fas', 'h-square'], 'https://www.hackerrank.com/konstDinev'),
    new MediaIcon(['fab', 'skype'], 'skype:zergling_?chat'),
    new MediaIcon(['fas', 'envelope-square'], 'mailto:konstDinev@gmail.com')
  ];

  const IconComponent = ({iconArray}) => {
    return (
      <div className="mediaWrapper">
        <h2>Get IN TOUCH</h2>
        <ul>
          {iconArray.map((ico, index) =>
            <li key={index}>
              <a className="socialLink" href={ico.url} target="_blank">
                <FontAwesomeIcon
                  icon={ico.icon}
                  color={ico.color}
                  size={ico.size}
                />
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }

  //SocialMenu render
  return (
    <section className="socialMediaSection">
      <div className="container">
        <IconComponent iconArray={icons} />
      </div>
    </section>
  );
}
